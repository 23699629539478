<template>
  <div class="workshop-inscriptions" ref="printMe" v-if="hasPerm('youth.view_seanceinscription')">
    <page-header
      :title="pageTitle"
      icon="fas fa-child-reaching"
      :links="getLinks"
      class="no-print"
    >
    </page-header>
    <b-row class="sub-header">
      <b-col cols="6">
        <h3>{{ title }}</h3>
      </b-col>
      <b-col cols="3">
        <div v-if="ageGroups.length > 1">
          <b-select id="ageGroups" v-model="selectedAgeGroup">
            <b-select-option :value="null">Tous</b-select-option>
            <b-select-option v-for="ageGroup in ageGroups" :key="ageGroup.id" :value="ageGroup">
              {{ ageGroup.name }}
            </b-select-option>
          </b-select>
        </div>
        <b-checkbox id="ordrByInscriptionsDate" v-model="ordrByInscriptionsDate">
          Trier par date d'inscription
        </b-checkbox>
      </b-col>
      <b-col cols="3" class="text-right">
        <b>
          <span v-if="selectedAgeGroup && !isLoading(loadingName)">
            <counter-label
              :counter="orderedInscriptions.length"
              :label="selectedAgeGroupLabel"
              :label-n="selectedAgeGroupLabel"
            >
            </counter-label>
            /
          </span>
          <counter-label :counter="inscriptions.length" label="inscrit" v-if="!isLoading(loadingName)">
          </counter-label>
        </b>
      </b-col>
    </b-row>
    <div>
      <loading-gif :loading-name="loadingName"></loading-gif>
      <div v-if="!isLoading(loadingName)" ref="excelMe">
        <table v-if="inscriptions.length" class="table-full table-striped table-bordered">
          <tr v-for="(inscription, index) of orderedInscriptions" :key="inscription.id">
            <td v-if="ordrByInscriptionsDate">
              {{ index + 1}}
            </td>
            <td>
              <a
                :href="getEntityLinkUrl(inscription.entity.id)"
                @click.prevent="showEntitySidebar(inscription.entity)"
              >
                {{ inscription.individual.lastName }}
              </a>
              <div v-if="showDocInfo && showIndividualLinkDocMissing(inscription)" class="badge badge-error">
                La fiche liaison est manquante
              </div>
              <div v-if="showDocInfo && showIndividualLinkDocOutdated(inscription)" class="badge badge-warning">
                La fiche liaison n'est pas à jour
              </div>
            </td>
            <td>
              <a
                :href="getEntityLinkUrl(inscription.entity.id, inscription.individual.id)"
                @click.prevent="showIndividualSidebar(inscription.individual)"
              >
                {{ inscription.individual.firstName }}
              </a>
            </td>
            <td>
              <span v-if="inscription.ageGroup">{{ inscription.ageGroup.name }}</span>
            </td>
            <td>
              <router-link :to="getSeanceLink(inscription.seance, day)">
                {{ inscription.seance.youthHome.name }}
              </router-link>
            </td>
            <td>
              <span v-if="inscription.absence" class="badge badge-warning">
                Absence
              </span>
            </td>
            <td v-if="ordrByInscriptionsDate">
              {{ inscription.createdOn | dateToString('DD/MM/YYYY HH:mm:ss')}}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import CounterLabel from '@/components/Controls/CounterLabel'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import { dateToString } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import { makeSeanceInscription } from '@/types/youth'
import { BackendApi, openDocument } from '@/utils/http'
import { slugify } from '@/utils/strings'
import { loadLinkDocuments } from '@/apis/youth'
import moment from 'moment'
import router from '@/router'
import { distinct } from '@/utils/arrays'
import { compareDates, compareNumbers } from '@/utils/sorting'

export default {
  name: 'WorkshopDailyInscriptions',
  mixins: [BackendMixin],
  components: {
    CounterLabel,
    LoadingGif,
    PageHeader,
  },
  props: {
    day: String,
    id: [String, Number],
    listType: String,
  },
  data() {
    return {
      loadingName: 'WorkshopDailyInscriptions',
      inscriptions: [],
      name: '',
      showDocInfo: false,
      missingDocIds: [],
      docsSavedOn: {},
      ordrByInscriptionsDate: false,
      selectedAgeGroup: null,
    }
  },
  watch: {
    day: function(value) { this.loadInscriptions(value, this.id, this.validListType) },
    id: function(value) { this.loadInscriptions(this.day, value, this.validListType) },
    validListType: function(value) { this.loadInscriptions(this.day, this.id, value) },
  },
  computed: {
    pageTitle() {
      if (this.listType === 'W') {
        return 'Atelier'
      } else if (this.listType === 'E') {
        return 'Sortie'
      } else if (this.listType === 'S') {
        return 'Séance'
      } else {
        return ''
      }
    },
    selectedAgeGroupLabel() {
      if (this.selectedAgeGroup) {
        return '"' + this.selectedAgeGroup.name + '"'
      } else {
        return ''
      }
    },
    ageGroups() {
      const ageGroups = distinct(
        this.inscriptions.map(elt => elt.ageGroup).filter(ageGroup => ageGroup && ageGroup.id)
      )
      return ageGroups.sort((gr1, gr2) => compareNumbers(gr1.startAge, gr2.startAge))
    },
    orderedInscriptions() {
      let inscriptions = [].concat(this.inscriptions)
      if (this.selectedAgeGroup) {
        inscriptions = inscriptions.filter(
          elt => (elt.ageGroup && (elt.ageGroup.id === this.selectedAgeGroup.id))
        )
      }
      if (this.ordrByInscriptionsDate) {
        return inscriptions.sort(
          (ins1, ins2) => compareDates(ins1.createdOn, ins2.createdOn)
        )
      } else {
        return inscriptions
      }
    },
    validListType() {
      if (this.listType === 'W') {
        return 'W'
      } else if (this.listType === 'E') {
        return 'E'
      } else if (this.listType === 'S') {
        return 'S'
      } else {
        return ''
      }
    },
    title() {
      let name = ''
      if (this.name) {
        name = this.name
        if (this.listType === 'W') {
          name += ' du ' + dateToString(this.day, 'dddd LL')
        }
      }
      return name
    },
    getLinks() {
      const links = [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
      if (this.hasPerm('documents.view_individuallinkdocument')) {
        links.push(
          {
            id: 3,
            label: 'Fiches Liaison',
            callback: this.loadLinkDocuments,
            icon: 'fa fa-download',
            cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
          }
        )
      }
      return links
    },
  },
  mounted() {
    this.onLoaded()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    onLoaded() {
      this.loadInscriptions(this.day, this.id, this.validListType)
    },
    getEntityLink(entityId, individualId) {
      const query = {}
      if (individualId) {
        query.individual = '' + individualId
      }
      return {
        name: 'families-detail',
        params: { entityId: '' + entityId, },
        query: query,
      }
    },
    getEntityLinkUrl(entityId, individualId = 0) {
      return router.resolve(this.getEntityLink(entityId, individualId)).href
    },
    getSeanceLink(seance, day) {
      let data = {
        name: 'youth-homes-day',
        params: {
          day: day,
          youthHome: '' + seance.youthHome.id,
          seanceType: '' + seance.seanceType.id,
          seancePeriod: '' + seance.period.id,
        },
      }
      return data
    },
    async loadInscriptions(day, id, listType) {
      this.startLoading(this.loadingName)
      if (day && (+id) && listType) {
        let url = '/api/youth/daily-workshops/' + day + '/' + listType + '/' + id + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.name = resp.data.name
          this.inscriptions = resp.data.inscriptions.map(elt => makeSeanceInscription(elt))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
      this.endLoading(this.loadingName)
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = slugify(this.name) + '-' + this.day
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = slugify(this.name) + '-' + this.day
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadLinkDocuments() {
      try {
        let resp = await loadLinkDocuments(this.day, this.inscriptions)
        this.showDocInfo = true
        this.docsSavedOn = resp.docsSavedOn
        this.missingDocIds = resp.missingDocIds
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
    },
    showIndividualLinkDocMissing(elt) {
      const docKey = '' + elt.individual.id + '-' + elt.entity.id
      return (this.missingDocIds.indexOf(docKey) >= 0)
    },
    showIndividualLinkDocOutdated(elt) {
      const docKey = '' + elt.individual.id + '-' + elt.entity.id
      if (this.docsSavedOn.hasOwnProperty(docKey)) {
        const docDate = moment(this.docsSavedOn[docKey])
        if (docDate < moment(elt.individual.updatedOn)) {
          return true
        }
      }
      return false
    },
  },
}
</script>

<style lang="less" scoped>
.table-full {
  min-width: 100%;
}
.table-full td, .table-full th{
  padding: 5px 10px;
}
</style>
